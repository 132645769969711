import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { dimensions, media, heights } from '../style/variables'
import { GraphQLGroup, pickGroup } from '../utils'
import { t } from '../i18n'
import Container from './Container'
import DrupalContext from '../context/drupal'
import SocialMedia from './blocks/SocialMedia'
import Hamburger from './blocks/Hamburger'

type MenuItem = {
	title: string
	langcode: string
	link: {
		alias: string
		uri: string
	}
}

interface StaticQueryProps {
	allMenuLinkContentMenuLinkContent: {
		group: GraphQLGroup<MenuItem>[]
	}
}

const StyledHamburger = styled(Hamburger)`
	${media.up('md')} {
		display: none;
	}
`

const StyledHeader = styled.header`
	--clr-background: var(--clr-primary);

	background-color: rgb(var(--clr-background));

	${media.up('md')} {
		position: absolute;
		background-color: transparent;
	}
	width: 100%;
	z-index: 100;
	color: white;
	font-weight: bold;
	padding: ${dimensions.containerPadding}rem 0;

	${Container} {
		display: grid;
		grid-template-columns: 5fr 1fr;
		grid-template-areas: 'menu social';
		grid-column-gap: ${dimensions.containerPadding}rem;
		position: relative;

		${media.up('md')} {
			justify-items: center;
			grid-template-columns: 0fr 10fr 1fr;
			grid-template-areas: '_ menu social';
		}
	}

	.navbar {
		grid-area: menu;
	}

	.navbar-nav {
		list-style: none;
		margin-bottom: 0;
		margin-right: auto;
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
		transform: translateX(-100vw);
		li {
			margin: 5px;
		}
		${media.up('md')} {
			margin-right: 0;
			transform: none;
		}

		${media.down('sm')} {
			margin-top: 1.5rem;
			flex-direction: column;
			padding: ${dimensions.containerPadding}rem;
			position: absolute;
			z-index: 100;
			top: 100%;
			left: 0;
			width: 100%;

			/** Background. */
			&:before {
				content: '';
				background-color: rgb(var(--clr-background));
				position: absolute;
				top: 0%;
				left: calc(0% - (100vw - 100%) / 2);
				width: 100vw;
				height: 100%;
				z-index: -50;
			}

			li + li {
				margin-top: 0.5rem;
			}
		}
	}

	[aria-expanded='true'] ~ .navbar-nav {
		transform: none;
	}

	.nav-link {
		color: white;
		text-decoration: none;
		align-items: center;
		white-space: nowrap;
		padding: 2px 0px;
		margin: 0px 10px;
		&.active {
			border-bottom: solid 2px #fafafa;
		}
	}

	.social-media {
		font-size: 1.125rem;
		flex-direction: row;
		margin-left: auto;
		display: flex;
		grid-area: social;

		li {
			padding: 0 0.75rem;
		}
	}
`

const Header: React.FC = () => {
	const { langcode } = useContext(DrupalContext)
	const [menuExpanded, setMenuExpanded] = useState(false)
	const menuContent: StaticQueryProps = useStaticQuery(graphql`
		query {
			allMenuLinkContentMenuLinkContent(filter: { menu_name: { eq: "main" } }, sort: { fields: [weight], order: ASC }) {
				group(field: langcode) {
					fieldValue
					nodes {
						title
						langcode
						link {
							uri
							alias
						}
					}
				}
			}
		}
	`)

	const menu = pickGroup(langcode, menuContent.allMenuLinkContentMenuLinkContent.group) || []

	const handleHamburgerClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		setMenuExpanded(!menuExpanded)
	}

	return (
		<StyledHeader>
			<Global
				/**
				 * Make room for absolutely positioned header.
				 */
				styles={css`
					${media.up('md')} {
						.paragraph:first-of-type .hero {
							padding-top: ${heights.header}rem;
						}
					}
				`}
			/>
			<Container>
				{menu.length > 0 && (
					<nav className="navbar">
						<StyledHamburger
							label={t('Open menu')}
							controls="navbar-nav"
							expanded={menuExpanded}
							onClick={handleHamburgerClick}
						/>

						<ul className="navbar-nav" id="navbar-nav" tabIndex={-1}>
							{menu.map(({ title, link: { alias, uri } }) => (
								<li key={alias}>
									{alias ? (
										<Link
											activeClassName="active"
											className="nav-link"
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											to={alias === process.env.GATSBY_DRUPAL_FRONTPAGE_URL! ? '/' : alias}
										>
											{title}
										</Link>
									) : (
										<a className="nav-link" href={uri} rel="noreferrer" target="_blank">
											{title}
										</a>
									)}
								</li>
							))}
						</ul>
					</nav>
				)}
				<SocialMedia label={t('Follow us on social media')} />
			</Container>
		</StyledHeader>
	)
}

export default Header
