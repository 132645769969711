import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { classNames, typenameToClass } from '../../utils'
import { DrupalMedia, DrupalMediaImage, DrupalMediaVideo, DrupalMediaGraphics, DrupalMediaLogo } from '../../drupal'
import Embed from './Embed'

interface Props extends React.HTMLProps<HTMLDivElement> {
	source?: DrupalMedia
}

function renderGraphics(media: DrupalMediaGraphics) {
	return (
		<img
			alt={media.field_media_image.alt}
			src={media.relationships.field_media_image.localFile.publicURL}
			draggable={false}
		/>
	)
}

function renderImage(media: DrupalMediaImage) {
	return (
		<GatsbyImage
			alt={media.field_media_image.alt || ''}
			image={media.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData}
		/>
	)
}

function renderIframe(media: DrupalMediaVideo) {
	return (
		<Embed width={media.thumbnail.width} height={media.thumbnail.height}>
			<iframe title={media.name} src={media.field_media_oembed_video} />
		</Embed>
	)
}

/**
 * Media logo is just like media image but with optional link field.
 */
function renderLogo(media: DrupalMediaLogo) {
	if (media.fields.link.uri) {
		return (
			<a href={media.fields.link.uri} title={media.fields.link.title}>
				<GatsbyImage
					alt={media.field_media_image.alt || ''}
					image={media.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData}
				/>
			</a>
		)
	}

	return (
		<GatsbyImage
			alt={media.field_media_image.alt || ''}
			image={media.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData}
		/>
	)
}

function renderMedia(media: DrupalMedia) {
	switch (media.__typename) {
		case 'media__graphics':
			return renderGraphics(media)
		case 'media__image':
			return renderImage(media)
		case 'media__remote_video':
			return renderIframe(media)
		case 'media__logo':
			return renderLogo(media)
	}
}

/**
 * Drupal media field
 *
 * Remember to query __typename when using this.
 */
const Media: React.FC<Props> = ({ source, className, ...props }) => (
	<div className={classNames(className, 'media', source ? typenameToClass(source.__typename) : 'no-media')} {...props}>
		{source && renderMedia(source)}
	</div>
)

export default Media

/**
 * There is no MediaImage fragment intentionally because image fields have
 * settings and we cannot cover all cases in generic fragments.
 *
 * Media logo width is fixed to 200 pixels.
 */
export const query = graphql`
	fragment MediaImageDefault on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
	}

	fragment MediaBackgroundImage on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
	}

	fragment MediaGraphics on media__graphics {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					extension
					publicURL
				}
			}
		}
	}

	fragment MediaRemoteVideo on media__remote_video {
		drupal_internal__mid
		name
		field_media_oembed_video
		thumbnail {
			height
			width
		}
		#relationships {
		#	thumbnail {
		#		localFile {
		#		}
		#	}
		#}
	}

	fragment MediaLogo on media__logo {
		drupal_internal__mid
		field_media_image {
			alt
		}
		fields {
			link {
				uri
				title
			}
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 200)
					}
				}
			}
		}
	}

	fragment MediaImageThumbnail on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						gatsbyImageData(height: 375, width: 570)
					}
				}
			}
		}
	}

	fragment MediaImageMedium on media__image {
		drupal_internal__mid
		field_media_image {
			alt
		}
		relationships {
			field_media_image {
				localFile {
					childImageSharp {
						# maxWidth should be around width of 'md' breakpoint.
						gatsbyImageData(height: 350, width: 350)
					}
				}
			}
		}
	}
`
