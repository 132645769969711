/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React from 'react'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/core'

import LanguageContext from '../context/drupal'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { DrupalNode, MetatagAttributes, NormalizedMetatag } from '../drupal'
import { Microdata } from '../Microdata'

// Include modern-normalize stylesheet.
import 'modern-normalize'

// Include global stylesheet.
import style from '../style'
import SiteStyles from '../components/SiteStyles'

interface LayoutProps {
	node: DrupalNode
	microdata?: Microdata
}

/**
 * Delete attributes when value is null.
 *
 * @param attributes metatag attributes.
 */
function cleanMetatagAttributes(attributes: MetatagAttributes) {
	Object.keys(attributes).forEach(key => attributes[key] == null && delete attributes[key])
	if (attributes.href) {
		attributes.href = attributes.href.replace('users.', '')
		attributes.href = attributes.href.replace(process.env.GATSBY_DRUPAL_FRONTPAGE_URL!, '/')
	}
	return attributes
}

/**
 * Render metatags from Drupal data.
 *
 * @param metatags
 */
function renderMetatags(metatags?: NormalizedMetatag[]) {
	if (metatags) {
		return metatags.map(({ tag, attributes }, index) =>
			React.createElement(tag, { key: index, ...cleanMetatagAttributes(attributes) })
		)
	}
}

const DefaultLayout: React.FC<LayoutProps> = ({ children, node, microdata }) => {
	// Use default settings if value is not defined.
	const langcode = node.langcode || process.env.GATSBY_LANGUAGE!
	const title = node.title || process.env.GATSBY_DEFAULT_TITLE!

	/**
	 * @todo: maybe place header and footer to templates.
	 */
	return (
		<>
			<Helmet>
				<html lang={langcode} />
				<title>{title}</title>

				<link rel="dns-prefetch" href={process.env.GATSBY_DRUPAL_HOST} />
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
				{process.env.GATSBY_PRIMARY_FONT && (
					<link
						href={`https://fonts.googleapis.com/css2?family=${process.env.GATSBY_PRIMARY_FONT}&display=swap`}
						rel="stylesheet"
					/>
				)}

				{renderMetatags(node.metatag_normalized)}

				{microdata && <script type="application/ld+json">{JSON.stringify(microdata)}</script>}
			</Helmet>
			<SiteStyles />
			<Global styles={style} />

			{/** Provide language to all components. */}
			<LanguageContext.Provider value={{ langcode }}>
				<Header />

				{children}

				<Footer />
			</LanguageContext.Provider>
		</>
	)
}

export default DefaultLayout
