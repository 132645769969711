import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import FieldMedia from '../fields/Media'
import { DrupalMedia } from '../../drupal'

type Props = React.HTMLProps<HTMLElement>

interface StaticQueryProps {
	blockContentSiteLogo: {
		langcode: string
		relationships: {
			field_site_logo: DrupalMedia
		}
	}
}

const StyledLogo = styled(FieldMedia)`
	width: 200px;

	img {
		width: 100%;
		height: auto;
	}
`

const Logo: React.FC<Props> = ({}) => {
	const menuContent: StaticQueryProps = useStaticQuery(graphql`
		query {
			blockContentSiteLogo {
				langcode
				relationships {
					field_site_logo {
						__typename
						... on media__graphics {
							...MediaGraphics
						}
						... on media__logo {
							...MediaLogo
						}
						... on media__image {
							...MediaImageDefault
						}
					}
				}
			}
		}
	`)

	return <StyledLogo source={menuContent.blockContentSiteLogo.relationships.field_site_logo} />
}

export default Logo
