import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { DrupalMediaImage } from '../../drupal'

interface Props {
	className?: string
	imgStyle?: React.CSSProperties
	style?: React.CSSProperties
	image?: DrupalMediaImage
}

/**
 * Helper component that is meant to handle the case when background image is not defined.
 *
 * https://www.gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/#background-images
 */
const WrapBackgroundImage: React.FC<Props> = ({ children, className, image }) => {
	const imageData = image?.relationships.field_media_image.localFile.childImageSharp.gatsbyImageData

	if (imageData) {
		return (
			<div style={{ display: 'grid', position: 'relative' }} className={className}>
				{/* You can use a GatsbyImage component if the image is dynamic */}
				<GatsbyImage
					style={{
						gridArea: '1/1',
						position: 'absolute',
						height: '100%',
						width: '100%'
					}}
					// This is a presentational image, so the alt should be an empty string
					alt=""
					image={imageData}
				/>
				<div
					className="bg-content"
					style={{
						// By using the same grid area for both, they are stacked on top of each other
						gridArea: '1/1',
						position: 'relative',
						// This centers the other elements inside the hero component
						placeItems: 'center',
						display: 'grid'
					}}
				>
					{/* Any content here will be centered in the component */}
					{children}
				</div>
			</div>
		)
	}

	return <div>{children}</div>
}

export default WrapBackgroundImage
